const middleware = {}

middleware['geolocator'] = require('../middleware/geolocator.ts')
middleware['geolocator'] = middleware['geolocator'].default || middleware['geolocator']

middleware['isAuthenticated'] = require('../middleware/isAuthenticated.js')
middleware['isAuthenticated'] = middleware['isAuthenticated'].default || middleware['isAuthenticated']

middleware['redirect'] = require('../middleware/redirect.ts')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
