import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8f04537e = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _5b4c2c9d = () => interopDefault(import('../pages/AllDesignersMen.vue' /* webpackChunkName: "" */))
const _8a61a9f6 = () => interopDefault(import('../pages/AllDesignersWomen.vue' /* webpackChunkName: "" */))
const _2c00cb04 = () => interopDefault(import('../pages/ArchiveSaleBergamo.vue' /* webpackChunkName: "" */))
const _34cef09d = () => interopDefault(import('../pages/Boutiques.vue' /* webpackChunkName: "pages/Boutiques" */))
const _544757fc = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _0b2841e0 = () => interopDefault(import('../pages/Category.vue' /* webpackChunkName: "pages/Category" */))
const _48ef6e28 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _26e24f74 = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _74dcbd02 = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _15bc54f6 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _42a0bda8 = () => interopDefault(import('../pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _7855b4db = () => interopDefault(import('../pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _97e42006 = () => interopDefault(import('../pages/Checkout/ExternalCheckoutThankYou.vue' /* webpackChunkName: "pages/Checkout/ExternalCheckoutThankYou" */))
const _7069626c = () => interopDefault(import('../pages/ContactUs.vue' /* webpackChunkName: "pages/ContactUs" */))
const _f8dba514 = () => interopDefault(import('../pages/Designers.vue' /* webpackChunkName: "pages/Designers" */))
const _107880d5 = () => interopDefault(import('../pages/Editorial.vue' /* webpackChunkName: "pages/Editorial" */))
const _10189cef = () => interopDefault(import('../pages/MyAccount.vue' /* webpackChunkName: "pages/MyAccount" */))
const _6f2f1c30 = () => interopDefault(import('../pages/MyAccount/AddressesDetails.vue' /* webpackChunkName: "pages/MyAccount/AddressesDetails" */))
const _a43397c6 = () => interopDefault(import('../pages/MyAccount/MyProfile.vue' /* webpackChunkName: "pages/MyAccount/MyProfile" */))
const _071fdb2d = () => interopDefault(import('../pages/MyAccount/NewsletterProfiling.vue' /* webpackChunkName: "pages/MyAccount/NewsletterProfiling" */))
const _42139b54 = () => interopDefault(import('../pages/MyAccount/OrderHistory.vue' /* webpackChunkName: "pages/MyAccount/OrderHistory" */))
const _016e88e9 = () => interopDefault(import('../pages/MyAccount/Overview.vue' /* webpackChunkName: "pages/MyAccount/Overview" */))
const _a85becca = () => interopDefault(import('../pages/Newsletter.vue' /* webpackChunkName: "" */))
const _08b2ebf1 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _4bba9d7d = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _2acc7f5c = () => interopDefault(import('../pages/Projects.vue' /* webpackChunkName: "pages/Projects" */))
const _693350d0 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _34cba952 = () => interopDefault(import('../pages/Route.vue' /* webpackChunkName: "pages/Route" */))
const _6f8b500a = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "" */))
const _16372a43 = () => interopDefault(import('../pages/Sitemap.vue' /* webpackChunkName: "" */))
const _3fbd01b2 = () => interopDefault(import('../pages/Wishlist.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/au",
    component: _8f04537e,
    name: "home___au"
  }, {
    path: "/bh",
    component: _8f04537e,
    name: "home___bh"
  }, {
    path: "/ca",
    component: _8f04537e,
    name: "home___ca"
  }, {
    path: "/ch",
    component: _8f04537e,
    name: "home___ch"
  }, {
    path: "/cn",
    component: _8f04537e,
    name: "home___cn"
  }, {
    path: "/en",
    component: _8f04537e,
    name: "home___en"
  }, {
    path: "/eu",
    component: _8f04537e,
    name: "home___eu"
  }, {
    path: "/eue",
    component: _8f04537e,
    name: "home___eue"
  }, {
    path: "/exu",
    component: _8f04537e,
    name: "home___exu"
  }, {
    path: "/fr",
    component: _8f04537e,
    name: "home___fr"
  }, {
    path: "/hk",
    component: _8f04537e,
    name: "home___hk"
  }, {
    path: "/it",
    component: _8f04537e,
    name: "home___it"
  }, {
    path: "/jp",
    component: _8f04537e,
    name: "home___jp"
  }, {
    path: "/kr",
    component: _8f04537e,
    name: "home___kr"
  }, {
    path: "/mo",
    component: _8f04537e,
    name: "home___mo"
  }, {
    path: "/qa",
    component: _8f04537e,
    name: "home___qa"
  }, {
    path: "/row",
    component: _8f04537e,
    name: "home___row"
  }, {
    path: "/sg",
    component: _8f04537e,
    name: "home___sg"
  }, {
    path: "/th",
    component: _8f04537e,
    name: "home___th"
  }, {
    path: "/uae",
    component: _8f04537e,
    name: "home___uae"
  }, {
    path: "/uk",
    component: _8f04537e,
    name: "home___uk"
  }, {
    path: "/us",
    component: _8f04537e,
    name: "home___us"
  }, {
    path: "/au/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___au"
  }, {
    path: "/au/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___au"
  }, {
    path: "/au/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___au"
  }, {
    path: "/au/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___au"
  }, {
    path: "/au/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___au"
  }, {
    path: "/au/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___au"
  }, {
    path: "/au/Boutiques",
    component: _34cef09d,
    name: "Boutiques___au"
  }, {
    path: "/au/Cart",
    component: _544757fc,
    name: "Cart___au"
  }, {
    path: "/au/Category",
    component: _0b2841e0,
    name: "Category___au"
  }, {
    path: "/au/checkout",
    component: _48ef6e28,
    name: "checkout___au",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___au"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___au"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___au"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___au"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___au"
    }]
  }, {
    path: "/au/Checkout",
    component: _48ef6e28,
    name: "Checkout___au",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___au"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___au"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___au"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___au"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___au"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___au"
    }]
  }, {
    path: "/au/ContactUs",
    component: _7069626c,
    name: "ContactUs___au"
  }, {
    path: "/au/Designers",
    component: _f8dba514,
    name: "Designers___au"
  }, {
    path: "/au/Editorial",
    component: _107880d5,
    name: "Editorial___au"
  }, {
    path: "/au/Home",
    component: _8f04537e,
    name: "Home___au"
  }, {
    path: "/au/MyAccount",
    component: _10189cef,
    name: "MyAccount___au",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___au"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___au"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___au"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___au"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___au"
    }]
  }, {
    path: "/au/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___au"
  }, {
    path: "/au/Newsletter",
    component: _a85becca,
    name: "Newsletter___au"
  }, {
    path: "/au/Page",
    component: _08b2ebf1,
    name: "Page___au"
  }, {
    path: "/au/Product",
    component: _4bba9d7d,
    name: "Product___au"
  }, {
    path: "/au/Projects",
    component: _2acc7f5c,
    name: "Projects___au"
  }, {
    path: "/au/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___au"
  }, {
    path: "/au/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___au"
  }, {
    path: "/au/Route",
    component: _34cba952,
    name: "Route___au"
  }, {
    path: "/au/search",
    component: _6f8b500a,
    name: "search___au"
  }, {
    path: "/au/Search",
    component: _6f8b500a,
    name: "Search___au"
  }, {
    path: "/au/sitemap",
    component: _16372a43,
    name: "sitemap___au"
  }, {
    path: "/au/Sitemap",
    component: _16372a43,
    name: "Sitemap___au"
  }, {
    path: "/au/wishlist",
    component: _3fbd01b2,
    name: "wishlist___au"
  }, {
    path: "/au/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___au"
  }, {
    path: "/bh/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___bh"
  }, {
    path: "/bh/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___bh"
  }, {
    path: "/bh/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___bh"
  }, {
    path: "/bh/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___bh"
  }, {
    path: "/bh/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___bh"
  }, {
    path: "/bh/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___bh"
  }, {
    path: "/bh/Boutiques",
    component: _34cef09d,
    name: "Boutiques___bh"
  }, {
    path: "/bh/Cart",
    component: _544757fc,
    name: "Cart___bh"
  }, {
    path: "/bh/Category",
    component: _0b2841e0,
    name: "Category___bh"
  }, {
    path: "/bh/checkout",
    component: _48ef6e28,
    name: "checkout___bh",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___bh"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___bh"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___bh"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___bh"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___bh"
    }]
  }, {
    path: "/bh/Checkout",
    component: _48ef6e28,
    name: "Checkout___bh",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___bh"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___bh"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___bh"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___bh"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___bh"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___bh"
    }]
  }, {
    path: "/bh/ContactUs",
    component: _7069626c,
    name: "ContactUs___bh"
  }, {
    path: "/bh/Designers",
    component: _f8dba514,
    name: "Designers___bh"
  }, {
    path: "/bh/Editorial",
    component: _107880d5,
    name: "Editorial___bh"
  }, {
    path: "/bh/Home",
    component: _8f04537e,
    name: "Home___bh"
  }, {
    path: "/bh/MyAccount",
    component: _10189cef,
    name: "MyAccount___bh",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___bh"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___bh"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___bh"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___bh"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___bh"
    }]
  }, {
    path: "/bh/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___bh"
  }, {
    path: "/bh/Newsletter",
    component: _a85becca,
    name: "Newsletter___bh"
  }, {
    path: "/bh/Page",
    component: _08b2ebf1,
    name: "Page___bh"
  }, {
    path: "/bh/Product",
    component: _4bba9d7d,
    name: "Product___bh"
  }, {
    path: "/bh/Projects",
    component: _2acc7f5c,
    name: "Projects___bh"
  }, {
    path: "/bh/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___bh"
  }, {
    path: "/bh/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___bh"
  }, {
    path: "/bh/Route",
    component: _34cba952,
    name: "Route___bh"
  }, {
    path: "/bh/search",
    component: _6f8b500a,
    name: "search___bh"
  }, {
    path: "/bh/Search",
    component: _6f8b500a,
    name: "Search___bh"
  }, {
    path: "/bh/sitemap",
    component: _16372a43,
    name: "sitemap___bh"
  }, {
    path: "/bh/Sitemap",
    component: _16372a43,
    name: "Sitemap___bh"
  }, {
    path: "/bh/wishlist",
    component: _3fbd01b2,
    name: "wishlist___bh"
  }, {
    path: "/bh/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___bh"
  }, {
    path: "/ca/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___ca"
  }, {
    path: "/ca/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___ca"
  }, {
    path: "/ca/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___ca"
  }, {
    path: "/ca/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___ca"
  }, {
    path: "/ca/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___ca"
  }, {
    path: "/ca/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___ca"
  }, {
    path: "/ca/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ca"
  }, {
    path: "/ca/Cart",
    component: _544757fc,
    name: "Cart___ca"
  }, {
    path: "/ca/Category",
    component: _0b2841e0,
    name: "Category___ca"
  }, {
    path: "/ca/checkout",
    component: _48ef6e28,
    name: "checkout___ca",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___ca"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___ca"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ca"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ca"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ca"
    }]
  }, {
    path: "/ca/Checkout",
    component: _48ef6e28,
    name: "Checkout___ca",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ca"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___ca"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ca"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ca"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ca"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ca"
    }]
  }, {
    path: "/ca/ContactUs",
    component: _7069626c,
    name: "ContactUs___ca"
  }, {
    path: "/ca/Designers",
    component: _f8dba514,
    name: "Designers___ca"
  }, {
    path: "/ca/Editorial",
    component: _107880d5,
    name: "Editorial___ca"
  }, {
    path: "/ca/Home",
    component: _8f04537e,
    name: "Home___ca"
  }, {
    path: "/ca/MyAccount",
    component: _10189cef,
    name: "MyAccount___ca",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ca"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ca"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___ca"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ca"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___ca"
    }]
  }, {
    path: "/ca/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___ca"
  }, {
    path: "/ca/Newsletter",
    component: _a85becca,
    name: "Newsletter___ca"
  }, {
    path: "/ca/Page",
    component: _08b2ebf1,
    name: "Page___ca"
  }, {
    path: "/ca/Product",
    component: _4bba9d7d,
    name: "Product___ca"
  }, {
    path: "/ca/Projects",
    component: _2acc7f5c,
    name: "Projects___ca"
  }, {
    path: "/ca/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ca"
  }, {
    path: "/ca/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ca"
  }, {
    path: "/ca/Route",
    component: _34cba952,
    name: "Route___ca"
  }, {
    path: "/ca/search",
    component: _6f8b500a,
    name: "search___ca"
  }, {
    path: "/ca/Search",
    component: _6f8b500a,
    name: "Search___ca"
  }, {
    path: "/ca/sitemap",
    component: _16372a43,
    name: "sitemap___ca"
  }, {
    path: "/ca/Sitemap",
    component: _16372a43,
    name: "Sitemap___ca"
  }, {
    path: "/ca/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ca"
  }, {
    path: "/ca/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ca"
  }, {
    path: "/ch/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___ch"
  }, {
    path: "/ch/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___ch"
  }, {
    path: "/ch/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___ch"
  }, {
    path: "/ch/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___ch"
  }, {
    path: "/ch/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___ch"
  }, {
    path: "/ch/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___ch"
  }, {
    path: "/ch/Boutiques",
    component: _34cef09d,
    name: "Boutiques___ch"
  }, {
    path: "/ch/Cart",
    component: _544757fc,
    name: "Cart___ch"
  }, {
    path: "/ch/Category",
    component: _0b2841e0,
    name: "Category___ch"
  }, {
    path: "/ch/checkout",
    component: _48ef6e28,
    name: "checkout___ch",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___ch"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___ch"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___ch"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___ch"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___ch"
    }]
  }, {
    path: "/ch/Checkout",
    component: _48ef6e28,
    name: "Checkout___ch",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___ch"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___ch"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___ch"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___ch"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___ch"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___ch"
    }]
  }, {
    path: "/ch/ContactUs",
    component: _7069626c,
    name: "ContactUs___ch"
  }, {
    path: "/ch/Designers",
    component: _f8dba514,
    name: "Designers___ch"
  }, {
    path: "/ch/Editorial",
    component: _107880d5,
    name: "Editorial___ch"
  }, {
    path: "/ch/Home",
    component: _8f04537e,
    name: "Home___ch"
  }, {
    path: "/ch/MyAccount",
    component: _10189cef,
    name: "MyAccount___ch",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___ch"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___ch"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___ch"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___ch"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___ch"
    }]
  }, {
    path: "/ch/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___ch"
  }, {
    path: "/ch/Newsletter",
    component: _a85becca,
    name: "Newsletter___ch"
  }, {
    path: "/ch/Page",
    component: _08b2ebf1,
    name: "Page___ch"
  }, {
    path: "/ch/Product",
    component: _4bba9d7d,
    name: "Product___ch"
  }, {
    path: "/ch/Projects",
    component: _2acc7f5c,
    name: "Projects___ch"
  }, {
    path: "/ch/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___ch"
  }, {
    path: "/ch/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___ch"
  }, {
    path: "/ch/Route",
    component: _34cba952,
    name: "Route___ch"
  }, {
    path: "/ch/search",
    component: _6f8b500a,
    name: "search___ch"
  }, {
    path: "/ch/Search",
    component: _6f8b500a,
    name: "Search___ch"
  }, {
    path: "/ch/sitemap",
    component: _16372a43,
    name: "sitemap___ch"
  }, {
    path: "/ch/Sitemap",
    component: _16372a43,
    name: "Sitemap___ch"
  }, {
    path: "/ch/wishlist",
    component: _3fbd01b2,
    name: "wishlist___ch"
  }, {
    path: "/ch/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___ch"
  }, {
    path: "/cn/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___cn"
  }, {
    path: "/cn/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___cn"
  }, {
    path: "/cn/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___cn"
  }, {
    path: "/cn/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___cn"
  }, {
    path: "/cn/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___cn"
  }, {
    path: "/cn/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___cn"
  }, {
    path: "/cn/Boutiques",
    component: _34cef09d,
    name: "Boutiques___cn"
  }, {
    path: "/cn/Cart",
    component: _544757fc,
    name: "Cart___cn"
  }, {
    path: "/cn/Category",
    component: _0b2841e0,
    name: "Category___cn"
  }, {
    path: "/cn/checkout",
    component: _48ef6e28,
    name: "checkout___cn",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___cn"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___cn"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___cn"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___cn"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___cn"
    }]
  }, {
    path: "/cn/Checkout",
    component: _48ef6e28,
    name: "Checkout___cn",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___cn"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___cn"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___cn"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___cn"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___cn"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___cn"
    }]
  }, {
    path: "/cn/ContactUs",
    component: _7069626c,
    name: "ContactUs___cn"
  }, {
    path: "/cn/Designers",
    component: _f8dba514,
    name: "Designers___cn"
  }, {
    path: "/cn/Editorial",
    component: _107880d5,
    name: "Editorial___cn"
  }, {
    path: "/cn/Home",
    component: _8f04537e,
    name: "Home___cn"
  }, {
    path: "/cn/MyAccount",
    component: _10189cef,
    name: "MyAccount___cn",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___cn"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___cn"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___cn"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___cn"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___cn"
    }]
  }, {
    path: "/cn/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___cn"
  }, {
    path: "/cn/Newsletter",
    component: _a85becca,
    name: "Newsletter___cn"
  }, {
    path: "/cn/Page",
    component: _08b2ebf1,
    name: "Page___cn"
  }, {
    path: "/cn/Product",
    component: _4bba9d7d,
    name: "Product___cn"
  }, {
    path: "/cn/Projects",
    component: _2acc7f5c,
    name: "Projects___cn"
  }, {
    path: "/cn/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___cn"
  }, {
    path: "/cn/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___cn"
  }, {
    path: "/cn/Route",
    component: _34cba952,
    name: "Route___cn"
  }, {
    path: "/cn/search",
    component: _6f8b500a,
    name: "search___cn"
  }, {
    path: "/cn/Search",
    component: _6f8b500a,
    name: "Search___cn"
  }, {
    path: "/cn/sitemap",
    component: _16372a43,
    name: "sitemap___cn"
  }, {
    path: "/cn/Sitemap",
    component: _16372a43,
    name: "Sitemap___cn"
  }, {
    path: "/cn/wishlist",
    component: _3fbd01b2,
    name: "wishlist___cn"
  }, {
    path: "/cn/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___cn"
  }, {
    path: "/en/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___en"
  }, {
    path: "/en/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___en"
  }, {
    path: "/en/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___en"
  }, {
    path: "/en/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___en"
  }, {
    path: "/en/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___en"
  }, {
    path: "/en/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___en"
  }, {
    path: "/en/Boutiques",
    component: _34cef09d,
    name: "Boutiques___en"
  }, {
    path: "/en/Cart",
    component: _544757fc,
    name: "Cart___en"
  }, {
    path: "/en/Category",
    component: _0b2841e0,
    name: "Category___en"
  }, {
    path: "/en/checkout",
    component: _48ef6e28,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___en"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Checkout",
    component: _48ef6e28,
    name: "Checkout___en",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___en"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___en"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___en"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___en"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___en"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___en"
    }]
  }, {
    path: "/en/ContactUs",
    component: _7069626c,
    name: "ContactUs___en"
  }, {
    path: "/en/Designers",
    component: _f8dba514,
    name: "Designers___en"
  }, {
    path: "/en/Editorial",
    component: _107880d5,
    name: "Editorial___en"
  }, {
    path: "/en/Home",
    component: _8f04537e,
    name: "Home___en"
  }, {
    path: "/en/MyAccount",
    component: _10189cef,
    name: "MyAccount___en",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___en"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___en"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___en"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___en"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___en"
    }]
  }, {
    path: "/en/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___en"
  }, {
    path: "/en/Newsletter",
    component: _a85becca,
    name: "Newsletter___en"
  }, {
    path: "/en/Page",
    component: _08b2ebf1,
    name: "Page___en"
  }, {
    path: "/en/Product",
    component: _4bba9d7d,
    name: "Product___en"
  }, {
    path: "/en/Projects",
    component: _2acc7f5c,
    name: "Projects___en"
  }, {
    path: "/en/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___en"
  }, {
    path: "/en/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___en"
  }, {
    path: "/en/Route",
    component: _34cba952,
    name: "Route___en"
  }, {
    path: "/en/search",
    component: _6f8b500a,
    name: "search___en"
  }, {
    path: "/en/Search",
    component: _6f8b500a,
    name: "Search___en"
  }, {
    path: "/en/sitemap",
    component: _16372a43,
    name: "sitemap___en"
  }, {
    path: "/en/Sitemap",
    component: _16372a43,
    name: "Sitemap___en"
  }, {
    path: "/en/wishlist",
    component: _3fbd01b2,
    name: "wishlist___en"
  }, {
    path: "/en/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___en"
  }, {
    path: "/eu/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___eu"
  }, {
    path: "/eu/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___eu"
  }, {
    path: "/eu/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___eu"
  }, {
    path: "/eu/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___eu"
  }, {
    path: "/eu/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___eu"
  }, {
    path: "/eu/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___eu"
  }, {
    path: "/eu/Boutiques",
    component: _34cef09d,
    name: "Boutiques___eu"
  }, {
    path: "/eu/Cart",
    component: _544757fc,
    name: "Cart___eu"
  }, {
    path: "/eu/Category",
    component: _0b2841e0,
    name: "Category___eu"
  }, {
    path: "/eu/checkout",
    component: _48ef6e28,
    name: "checkout___eu",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___eu"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___eu"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___eu"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___eu"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___eu"
    }]
  }, {
    path: "/eu/Checkout",
    component: _48ef6e28,
    name: "Checkout___eu",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___eu"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___eu"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___eu"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___eu"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___eu"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___eu"
    }]
  }, {
    path: "/eu/ContactUs",
    component: _7069626c,
    name: "ContactUs___eu"
  }, {
    path: "/eu/Designers",
    component: _f8dba514,
    name: "Designers___eu"
  }, {
    path: "/eu/Editorial",
    component: _107880d5,
    name: "Editorial___eu"
  }, {
    path: "/eu/Home",
    component: _8f04537e,
    name: "Home___eu"
  }, {
    path: "/eu/MyAccount",
    component: _10189cef,
    name: "MyAccount___eu",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___eu"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___eu"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___eu"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___eu"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___eu"
    }]
  }, {
    path: "/eu/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___eu"
  }, {
    path: "/eu/Newsletter",
    component: _a85becca,
    name: "Newsletter___eu"
  }, {
    path: "/eu/Page",
    component: _08b2ebf1,
    name: "Page___eu"
  }, {
    path: "/eu/Product",
    component: _4bba9d7d,
    name: "Product___eu"
  }, {
    path: "/eu/Projects",
    component: _2acc7f5c,
    name: "Projects___eu"
  }, {
    path: "/eu/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___eu"
  }, {
    path: "/eu/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___eu"
  }, {
    path: "/eu/Route",
    component: _34cba952,
    name: "Route___eu"
  }, {
    path: "/eu/search",
    component: _6f8b500a,
    name: "search___eu"
  }, {
    path: "/eu/Search",
    component: _6f8b500a,
    name: "Search___eu"
  }, {
    path: "/eu/sitemap",
    component: _16372a43,
    name: "sitemap___eu"
  }, {
    path: "/eu/Sitemap",
    component: _16372a43,
    name: "Sitemap___eu"
  }, {
    path: "/eu/wishlist",
    component: _3fbd01b2,
    name: "wishlist___eu"
  }, {
    path: "/eu/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___eu"
  }, {
    path: "/eue/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___eue"
  }, {
    path: "/eue/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___eue"
  }, {
    path: "/eue/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___eue"
  }, {
    path: "/eue/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___eue"
  }, {
    path: "/eue/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___eue"
  }, {
    path: "/eue/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___eue"
  }, {
    path: "/eue/Boutiques",
    component: _34cef09d,
    name: "Boutiques___eue"
  }, {
    path: "/eue/Cart",
    component: _544757fc,
    name: "Cart___eue"
  }, {
    path: "/eue/Category",
    component: _0b2841e0,
    name: "Category___eue"
  }, {
    path: "/eue/checkout",
    component: _48ef6e28,
    name: "checkout___eue",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___eue"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___eue"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___eue"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___eue"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___eue"
    }]
  }, {
    path: "/eue/Checkout",
    component: _48ef6e28,
    name: "Checkout___eue",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___eue"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___eue"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___eue"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___eue"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___eue"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___eue"
    }]
  }, {
    path: "/eue/ContactUs",
    component: _7069626c,
    name: "ContactUs___eue"
  }, {
    path: "/eue/Designers",
    component: _f8dba514,
    name: "Designers___eue"
  }, {
    path: "/eue/Editorial",
    component: _107880d5,
    name: "Editorial___eue"
  }, {
    path: "/eue/Home",
    component: _8f04537e,
    name: "Home___eue"
  }, {
    path: "/eue/MyAccount",
    component: _10189cef,
    name: "MyAccount___eue",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___eue"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___eue"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___eue"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___eue"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___eue"
    }]
  }, {
    path: "/eue/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___eue"
  }, {
    path: "/eue/Newsletter",
    component: _a85becca,
    name: "Newsletter___eue"
  }, {
    path: "/eue/Page",
    component: _08b2ebf1,
    name: "Page___eue"
  }, {
    path: "/eue/Product",
    component: _4bba9d7d,
    name: "Product___eue"
  }, {
    path: "/eue/Projects",
    component: _2acc7f5c,
    name: "Projects___eue"
  }, {
    path: "/eue/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___eue"
  }, {
    path: "/eue/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___eue"
  }, {
    path: "/eue/Route",
    component: _34cba952,
    name: "Route___eue"
  }, {
    path: "/eue/search",
    component: _6f8b500a,
    name: "search___eue"
  }, {
    path: "/eue/Search",
    component: _6f8b500a,
    name: "Search___eue"
  }, {
    path: "/eue/sitemap",
    component: _16372a43,
    name: "sitemap___eue"
  }, {
    path: "/eue/Sitemap",
    component: _16372a43,
    name: "Sitemap___eue"
  }, {
    path: "/eue/wishlist",
    component: _3fbd01b2,
    name: "wishlist___eue"
  }, {
    path: "/eue/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___eue"
  }, {
    path: "/exu/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___exu"
  }, {
    path: "/exu/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___exu"
  }, {
    path: "/exu/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___exu"
  }, {
    path: "/exu/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___exu"
  }, {
    path: "/exu/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___exu"
  }, {
    path: "/exu/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___exu"
  }, {
    path: "/exu/Boutiques",
    component: _34cef09d,
    name: "Boutiques___exu"
  }, {
    path: "/exu/Cart",
    component: _544757fc,
    name: "Cart___exu"
  }, {
    path: "/exu/Category",
    component: _0b2841e0,
    name: "Category___exu"
  }, {
    path: "/exu/checkout",
    component: _48ef6e28,
    name: "checkout___exu",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___exu"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___exu"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___exu"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___exu"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___exu"
    }]
  }, {
    path: "/exu/Checkout",
    component: _48ef6e28,
    name: "Checkout___exu",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___exu"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___exu"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___exu"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___exu"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___exu"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___exu"
    }]
  }, {
    path: "/exu/ContactUs",
    component: _7069626c,
    name: "ContactUs___exu"
  }, {
    path: "/exu/Designers",
    component: _f8dba514,
    name: "Designers___exu"
  }, {
    path: "/exu/Editorial",
    component: _107880d5,
    name: "Editorial___exu"
  }, {
    path: "/exu/Home",
    component: _8f04537e,
    name: "Home___exu"
  }, {
    path: "/exu/MyAccount",
    component: _10189cef,
    name: "MyAccount___exu",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___exu"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___exu"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___exu"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___exu"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___exu"
    }]
  }, {
    path: "/exu/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___exu"
  }, {
    path: "/exu/Newsletter",
    component: _a85becca,
    name: "Newsletter___exu"
  }, {
    path: "/exu/Page",
    component: _08b2ebf1,
    name: "Page___exu"
  }, {
    path: "/exu/Product",
    component: _4bba9d7d,
    name: "Product___exu"
  }, {
    path: "/exu/Projects",
    component: _2acc7f5c,
    name: "Projects___exu"
  }, {
    path: "/exu/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___exu"
  }, {
    path: "/exu/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___exu"
  }, {
    path: "/exu/Route",
    component: _34cba952,
    name: "Route___exu"
  }, {
    path: "/exu/search",
    component: _6f8b500a,
    name: "search___exu"
  }, {
    path: "/exu/Search",
    component: _6f8b500a,
    name: "Search___exu"
  }, {
    path: "/exu/sitemap",
    component: _16372a43,
    name: "sitemap___exu"
  }, {
    path: "/exu/Sitemap",
    component: _16372a43,
    name: "Sitemap___exu"
  }, {
    path: "/exu/wishlist",
    component: _3fbd01b2,
    name: "wishlist___exu"
  }, {
    path: "/exu/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___exu"
  }, {
    path: "/fr/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___fr"
  }, {
    path: "/fr/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___fr"
  }, {
    path: "/fr/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___fr"
  }, {
    path: "/fr/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___fr"
  }, {
    path: "/fr/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___fr"
  }, {
    path: "/fr/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___fr"
  }, {
    path: "/fr/Boutiques",
    component: _34cef09d,
    name: "Boutiques___fr"
  }, {
    path: "/fr/Cart",
    component: _544757fc,
    name: "Cart___fr"
  }, {
    path: "/fr/Category",
    component: _0b2841e0,
    name: "Category___fr"
  }, {
    path: "/fr/checkout",
    component: _48ef6e28,
    name: "checkout___fr",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___fr"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___fr"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___fr"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___fr"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___fr"
    }]
  }, {
    path: "/fr/Checkout",
    component: _48ef6e28,
    name: "Checkout___fr",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___fr"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___fr"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___fr"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___fr"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___fr"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___fr"
    }]
  }, {
    path: "/fr/ContactUs",
    component: _7069626c,
    name: "ContactUs___fr"
  }, {
    path: "/fr/Designers",
    component: _f8dba514,
    name: "Designers___fr"
  }, {
    path: "/fr/Editorial",
    component: _107880d5,
    name: "Editorial___fr"
  }, {
    path: "/fr/Home",
    component: _8f04537e,
    name: "Home___fr"
  }, {
    path: "/fr/MyAccount",
    component: _10189cef,
    name: "MyAccount___fr",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___fr"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___fr"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___fr"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___fr"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___fr"
    }]
  }, {
    path: "/fr/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___fr"
  }, {
    path: "/fr/Newsletter",
    component: _a85becca,
    name: "Newsletter___fr"
  }, {
    path: "/fr/Page",
    component: _08b2ebf1,
    name: "Page___fr"
  }, {
    path: "/fr/Product",
    component: _4bba9d7d,
    name: "Product___fr"
  }, {
    path: "/fr/Projects",
    component: _2acc7f5c,
    name: "Projects___fr"
  }, {
    path: "/fr/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___fr"
  }, {
    path: "/fr/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___fr"
  }, {
    path: "/fr/Route",
    component: _34cba952,
    name: "Route___fr"
  }, {
    path: "/fr/search",
    component: _6f8b500a,
    name: "search___fr"
  }, {
    path: "/fr/Search",
    component: _6f8b500a,
    name: "Search___fr"
  }, {
    path: "/fr/sitemap",
    component: _16372a43,
    name: "sitemap___fr"
  }, {
    path: "/fr/Sitemap",
    component: _16372a43,
    name: "Sitemap___fr"
  }, {
    path: "/fr/wishlist",
    component: _3fbd01b2,
    name: "wishlist___fr"
  }, {
    path: "/fr/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___fr"
  }, {
    path: "/hk/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___hk"
  }, {
    path: "/hk/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___hk"
  }, {
    path: "/hk/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___hk"
  }, {
    path: "/hk/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___hk"
  }, {
    path: "/hk/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___hk"
  }, {
    path: "/hk/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___hk"
  }, {
    path: "/hk/Boutiques",
    component: _34cef09d,
    name: "Boutiques___hk"
  }, {
    path: "/hk/Cart",
    component: _544757fc,
    name: "Cart___hk"
  }, {
    path: "/hk/Category",
    component: _0b2841e0,
    name: "Category___hk"
  }, {
    path: "/hk/checkout",
    component: _48ef6e28,
    name: "checkout___hk",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___hk"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___hk"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___hk"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___hk"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___hk"
    }]
  }, {
    path: "/hk/Checkout",
    component: _48ef6e28,
    name: "Checkout___hk",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___hk"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___hk"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___hk"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___hk"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___hk"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___hk"
    }]
  }, {
    path: "/hk/ContactUs",
    component: _7069626c,
    name: "ContactUs___hk"
  }, {
    path: "/hk/Designers",
    component: _f8dba514,
    name: "Designers___hk"
  }, {
    path: "/hk/Editorial",
    component: _107880d5,
    name: "Editorial___hk"
  }, {
    path: "/hk/Home",
    component: _8f04537e,
    name: "Home___hk"
  }, {
    path: "/hk/MyAccount",
    component: _10189cef,
    name: "MyAccount___hk",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___hk"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___hk"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___hk"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___hk"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___hk"
    }]
  }, {
    path: "/hk/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___hk"
  }, {
    path: "/hk/Newsletter",
    component: _a85becca,
    name: "Newsletter___hk"
  }, {
    path: "/hk/Page",
    component: _08b2ebf1,
    name: "Page___hk"
  }, {
    path: "/hk/Product",
    component: _4bba9d7d,
    name: "Product___hk"
  }, {
    path: "/hk/Projects",
    component: _2acc7f5c,
    name: "Projects___hk"
  }, {
    path: "/hk/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___hk"
  }, {
    path: "/hk/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___hk"
  }, {
    path: "/hk/Route",
    component: _34cba952,
    name: "Route___hk"
  }, {
    path: "/hk/search",
    component: _6f8b500a,
    name: "search___hk"
  }, {
    path: "/hk/Search",
    component: _6f8b500a,
    name: "Search___hk"
  }, {
    path: "/hk/sitemap",
    component: _16372a43,
    name: "sitemap___hk"
  }, {
    path: "/hk/Sitemap",
    component: _16372a43,
    name: "Sitemap___hk"
  }, {
    path: "/hk/wishlist",
    component: _3fbd01b2,
    name: "wishlist___hk"
  }, {
    path: "/hk/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___hk"
  }, {
    path: "/it/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___it"
  }, {
    path: "/it/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___it"
  }, {
    path: "/it/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___it"
  }, {
    path: "/it/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___it"
  }, {
    path: "/it/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___it"
  }, {
    path: "/it/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___it"
  }, {
    path: "/it/Boutiques",
    component: _34cef09d,
    name: "Boutiques___it"
  }, {
    path: "/it/Cart",
    component: _544757fc,
    name: "Cart___it"
  }, {
    path: "/it/Category",
    component: _0b2841e0,
    name: "Category___it"
  }, {
    path: "/it/checkout",
    component: _48ef6e28,
    name: "checkout___it",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___it"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___it"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___it"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___it"
    }]
  }, {
    path: "/it/Checkout",
    component: _48ef6e28,
    name: "Checkout___it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___it"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___it"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___it"
    }]
  }, {
    path: "/it/ContactUs",
    component: _7069626c,
    name: "ContactUs___it"
  }, {
    path: "/it/Designers",
    component: _f8dba514,
    name: "Designers___it"
  }, {
    path: "/it/Editorial",
    component: _107880d5,
    name: "Editorial___it"
  }, {
    path: "/it/Home",
    component: _8f04537e,
    name: "Home___it"
  }, {
    path: "/it/MyAccount",
    component: _10189cef,
    name: "MyAccount___it",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___it"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___it"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___it"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___it"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___it"
    }]
  }, {
    path: "/it/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___it"
  }, {
    path: "/it/Newsletter",
    component: _a85becca,
    name: "Newsletter___it"
  }, {
    path: "/it/Page",
    component: _08b2ebf1,
    name: "Page___it"
  }, {
    path: "/it/Product",
    component: _4bba9d7d,
    name: "Product___it"
  }, {
    path: "/it/Projects",
    component: _2acc7f5c,
    name: "Projects___it"
  }, {
    path: "/it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___it"
  }, {
    path: "/it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___it"
  }, {
    path: "/it/Route",
    component: _34cba952,
    name: "Route___it"
  }, {
    path: "/it/search",
    component: _6f8b500a,
    name: "search___it"
  }, {
    path: "/it/Search",
    component: _6f8b500a,
    name: "Search___it"
  }, {
    path: "/it/sitemap",
    component: _16372a43,
    name: "sitemap___it"
  }, {
    path: "/it/Sitemap",
    component: _16372a43,
    name: "Sitemap___it"
  }, {
    path: "/it/wishlist",
    component: _3fbd01b2,
    name: "wishlist___it"
  }, {
    path: "/it/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___it"
  }, {
    path: "/jp/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___jp"
  }, {
    path: "/jp/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___jp"
  }, {
    path: "/jp/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___jp"
  }, {
    path: "/jp/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___jp"
  }, {
    path: "/jp/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___jp"
  }, {
    path: "/jp/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___jp"
  }, {
    path: "/jp/Boutiques",
    component: _34cef09d,
    name: "Boutiques___jp"
  }, {
    path: "/jp/Cart",
    component: _544757fc,
    name: "Cart___jp"
  }, {
    path: "/jp/Category",
    component: _0b2841e0,
    name: "Category___jp"
  }, {
    path: "/jp/checkout",
    component: _48ef6e28,
    name: "checkout___jp",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___jp"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___jp"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___jp"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___jp"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___jp"
    }]
  }, {
    path: "/jp/Checkout",
    component: _48ef6e28,
    name: "Checkout___jp",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___jp"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___jp"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___jp"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___jp"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___jp"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___jp"
    }]
  }, {
    path: "/jp/ContactUs",
    component: _7069626c,
    name: "ContactUs___jp"
  }, {
    path: "/jp/Designers",
    component: _f8dba514,
    name: "Designers___jp"
  }, {
    path: "/jp/Editorial",
    component: _107880d5,
    name: "Editorial___jp"
  }, {
    path: "/jp/Home",
    component: _8f04537e,
    name: "Home___jp"
  }, {
    path: "/jp/MyAccount",
    component: _10189cef,
    name: "MyAccount___jp",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___jp"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___jp"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___jp"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___jp"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___jp"
    }]
  }, {
    path: "/jp/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___jp"
  }, {
    path: "/jp/Newsletter",
    component: _a85becca,
    name: "Newsletter___jp"
  }, {
    path: "/jp/Page",
    component: _08b2ebf1,
    name: "Page___jp"
  }, {
    path: "/jp/Product",
    component: _4bba9d7d,
    name: "Product___jp"
  }, {
    path: "/jp/Projects",
    component: _2acc7f5c,
    name: "Projects___jp"
  }, {
    path: "/jp/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___jp"
  }, {
    path: "/jp/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___jp"
  }, {
    path: "/jp/Route",
    component: _34cba952,
    name: "Route___jp"
  }, {
    path: "/jp/search",
    component: _6f8b500a,
    name: "search___jp"
  }, {
    path: "/jp/Search",
    component: _6f8b500a,
    name: "Search___jp"
  }, {
    path: "/jp/sitemap",
    component: _16372a43,
    name: "sitemap___jp"
  }, {
    path: "/jp/Sitemap",
    component: _16372a43,
    name: "Sitemap___jp"
  }, {
    path: "/jp/wishlist",
    component: _3fbd01b2,
    name: "wishlist___jp"
  }, {
    path: "/jp/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___jp"
  }, {
    path: "/kr/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___kr"
  }, {
    path: "/kr/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___kr"
  }, {
    path: "/kr/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___kr"
  }, {
    path: "/kr/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___kr"
  }, {
    path: "/kr/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___kr"
  }, {
    path: "/kr/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___kr"
  }, {
    path: "/kr/Boutiques",
    component: _34cef09d,
    name: "Boutiques___kr"
  }, {
    path: "/kr/Cart",
    component: _544757fc,
    name: "Cart___kr"
  }, {
    path: "/kr/Category",
    component: _0b2841e0,
    name: "Category___kr"
  }, {
    path: "/kr/checkout",
    component: _48ef6e28,
    name: "checkout___kr",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___kr"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___kr"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___kr"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___kr"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___kr"
    }]
  }, {
    path: "/kr/Checkout",
    component: _48ef6e28,
    name: "Checkout___kr",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___kr"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___kr"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___kr"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___kr"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___kr"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___kr"
    }]
  }, {
    path: "/kr/ContactUs",
    component: _7069626c,
    name: "ContactUs___kr"
  }, {
    path: "/kr/Designers",
    component: _f8dba514,
    name: "Designers___kr"
  }, {
    path: "/kr/Editorial",
    component: _107880d5,
    name: "Editorial___kr"
  }, {
    path: "/kr/Home",
    component: _8f04537e,
    name: "Home___kr"
  }, {
    path: "/kr/MyAccount",
    component: _10189cef,
    name: "MyAccount___kr",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___kr"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___kr"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___kr"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___kr"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___kr"
    }]
  }, {
    path: "/kr/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___kr"
  }, {
    path: "/kr/Newsletter",
    component: _a85becca,
    name: "Newsletter___kr"
  }, {
    path: "/kr/Page",
    component: _08b2ebf1,
    name: "Page___kr"
  }, {
    path: "/kr/Product",
    component: _4bba9d7d,
    name: "Product___kr"
  }, {
    path: "/kr/Projects",
    component: _2acc7f5c,
    name: "Projects___kr"
  }, {
    path: "/kr/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___kr"
  }, {
    path: "/kr/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___kr"
  }, {
    path: "/kr/Route",
    component: _34cba952,
    name: "Route___kr"
  }, {
    path: "/kr/search",
    component: _6f8b500a,
    name: "search___kr"
  }, {
    path: "/kr/Search",
    component: _6f8b500a,
    name: "Search___kr"
  }, {
    path: "/kr/sitemap",
    component: _16372a43,
    name: "sitemap___kr"
  }, {
    path: "/kr/Sitemap",
    component: _16372a43,
    name: "Sitemap___kr"
  }, {
    path: "/kr/wishlist",
    component: _3fbd01b2,
    name: "wishlist___kr"
  }, {
    path: "/kr/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___kr"
  }, {
    path: "/mo/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___mo"
  }, {
    path: "/mo/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___mo"
  }, {
    path: "/mo/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___mo"
  }, {
    path: "/mo/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___mo"
  }, {
    path: "/mo/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___mo"
  }, {
    path: "/mo/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___mo"
  }, {
    path: "/mo/Boutiques",
    component: _34cef09d,
    name: "Boutiques___mo"
  }, {
    path: "/mo/Cart",
    component: _544757fc,
    name: "Cart___mo"
  }, {
    path: "/mo/Category",
    component: _0b2841e0,
    name: "Category___mo"
  }, {
    path: "/mo/checkout",
    component: _48ef6e28,
    name: "checkout___mo",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___mo"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___mo"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___mo"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___mo"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___mo"
    }]
  }, {
    path: "/mo/Checkout",
    component: _48ef6e28,
    name: "Checkout___mo",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___mo"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___mo"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___mo"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___mo"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___mo"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___mo"
    }]
  }, {
    path: "/mo/ContactUs",
    component: _7069626c,
    name: "ContactUs___mo"
  }, {
    path: "/mo/Designers",
    component: _f8dba514,
    name: "Designers___mo"
  }, {
    path: "/mo/Editorial",
    component: _107880d5,
    name: "Editorial___mo"
  }, {
    path: "/mo/Home",
    component: _8f04537e,
    name: "Home___mo"
  }, {
    path: "/mo/MyAccount",
    component: _10189cef,
    name: "MyAccount___mo",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___mo"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___mo"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___mo"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___mo"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___mo"
    }]
  }, {
    path: "/mo/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___mo"
  }, {
    path: "/mo/Newsletter",
    component: _a85becca,
    name: "Newsletter___mo"
  }, {
    path: "/mo/Page",
    component: _08b2ebf1,
    name: "Page___mo"
  }, {
    path: "/mo/Product",
    component: _4bba9d7d,
    name: "Product___mo"
  }, {
    path: "/mo/Projects",
    component: _2acc7f5c,
    name: "Projects___mo"
  }, {
    path: "/mo/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___mo"
  }, {
    path: "/mo/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___mo"
  }, {
    path: "/mo/Route",
    component: _34cba952,
    name: "Route___mo"
  }, {
    path: "/mo/search",
    component: _6f8b500a,
    name: "search___mo"
  }, {
    path: "/mo/Search",
    component: _6f8b500a,
    name: "Search___mo"
  }, {
    path: "/mo/sitemap",
    component: _16372a43,
    name: "sitemap___mo"
  }, {
    path: "/mo/Sitemap",
    component: _16372a43,
    name: "Sitemap___mo"
  }, {
    path: "/mo/wishlist",
    component: _3fbd01b2,
    name: "wishlist___mo"
  }, {
    path: "/mo/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___mo"
  }, {
    path: "/qa/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___qa"
  }, {
    path: "/qa/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___qa"
  }, {
    path: "/qa/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___qa"
  }, {
    path: "/qa/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___qa"
  }, {
    path: "/qa/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___qa"
  }, {
    path: "/qa/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___qa"
  }, {
    path: "/qa/Boutiques",
    component: _34cef09d,
    name: "Boutiques___qa"
  }, {
    path: "/qa/Cart",
    component: _544757fc,
    name: "Cart___qa"
  }, {
    path: "/qa/Category",
    component: _0b2841e0,
    name: "Category___qa"
  }, {
    path: "/qa/checkout",
    component: _48ef6e28,
    name: "checkout___qa",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___qa"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___qa"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___qa"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___qa"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___qa"
    }]
  }, {
    path: "/qa/Checkout",
    component: _48ef6e28,
    name: "Checkout___qa",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___qa"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___qa"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___qa"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___qa"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___qa"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___qa"
    }]
  }, {
    path: "/qa/ContactUs",
    component: _7069626c,
    name: "ContactUs___qa"
  }, {
    path: "/qa/Designers",
    component: _f8dba514,
    name: "Designers___qa"
  }, {
    path: "/qa/Editorial",
    component: _107880d5,
    name: "Editorial___qa"
  }, {
    path: "/qa/Home",
    component: _8f04537e,
    name: "Home___qa"
  }, {
    path: "/qa/MyAccount",
    component: _10189cef,
    name: "MyAccount___qa",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___qa"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___qa"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___qa"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___qa"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___qa"
    }]
  }, {
    path: "/qa/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___qa"
  }, {
    path: "/qa/Newsletter",
    component: _a85becca,
    name: "Newsletter___qa"
  }, {
    path: "/qa/Page",
    component: _08b2ebf1,
    name: "Page___qa"
  }, {
    path: "/qa/Product",
    component: _4bba9d7d,
    name: "Product___qa"
  }, {
    path: "/qa/Projects",
    component: _2acc7f5c,
    name: "Projects___qa"
  }, {
    path: "/qa/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___qa"
  }, {
    path: "/qa/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___qa"
  }, {
    path: "/qa/Route",
    component: _34cba952,
    name: "Route___qa"
  }, {
    path: "/qa/search",
    component: _6f8b500a,
    name: "search___qa"
  }, {
    path: "/qa/Search",
    component: _6f8b500a,
    name: "Search___qa"
  }, {
    path: "/qa/sitemap",
    component: _16372a43,
    name: "sitemap___qa"
  }, {
    path: "/qa/Sitemap",
    component: _16372a43,
    name: "Sitemap___qa"
  }, {
    path: "/qa/wishlist",
    component: _3fbd01b2,
    name: "wishlist___qa"
  }, {
    path: "/qa/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___qa"
  }, {
    path: "/row/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___row"
  }, {
    path: "/row/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___row"
  }, {
    path: "/row/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___row"
  }, {
    path: "/row/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___row"
  }, {
    path: "/row/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___row"
  }, {
    path: "/row/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___row"
  }, {
    path: "/row/Boutiques",
    component: _34cef09d,
    name: "Boutiques___row"
  }, {
    path: "/row/Cart",
    component: _544757fc,
    name: "Cart___row"
  }, {
    path: "/row/Category",
    component: _0b2841e0,
    name: "Category___row"
  }, {
    path: "/row/checkout",
    component: _48ef6e28,
    name: "checkout___row",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___row"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___row"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___row"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___row"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___row"
    }]
  }, {
    path: "/row/Checkout",
    component: _48ef6e28,
    name: "Checkout___row",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___row"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___row"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___row"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___row"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___row"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___row"
    }]
  }, {
    path: "/row/ContactUs",
    component: _7069626c,
    name: "ContactUs___row"
  }, {
    path: "/row/Designers",
    component: _f8dba514,
    name: "Designers___row"
  }, {
    path: "/row/Editorial",
    component: _107880d5,
    name: "Editorial___row"
  }, {
    path: "/row/Home",
    component: _8f04537e,
    name: "Home___row"
  }, {
    path: "/row/MyAccount",
    component: _10189cef,
    name: "MyAccount___row",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___row"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___row"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___row"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___row"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___row"
    }]
  }, {
    path: "/row/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___row"
  }, {
    path: "/row/Newsletter",
    component: _a85becca,
    name: "Newsletter___row"
  }, {
    path: "/row/Page",
    component: _08b2ebf1,
    name: "Page___row"
  }, {
    path: "/row/Product",
    component: _4bba9d7d,
    name: "Product___row"
  }, {
    path: "/row/Projects",
    component: _2acc7f5c,
    name: "Projects___row"
  }, {
    path: "/row/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___row"
  }, {
    path: "/row/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___row"
  }, {
    path: "/row/Route",
    component: _34cba952,
    name: "Route___row"
  }, {
    path: "/row/search",
    component: _6f8b500a,
    name: "search___row"
  }, {
    path: "/row/Search",
    component: _6f8b500a,
    name: "Search___row"
  }, {
    path: "/row/sitemap",
    component: _16372a43,
    name: "sitemap___row"
  }, {
    path: "/row/Sitemap",
    component: _16372a43,
    name: "Sitemap___row"
  }, {
    path: "/row/wishlist",
    component: _3fbd01b2,
    name: "wishlist___row"
  }, {
    path: "/row/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___row"
  }, {
    path: "/sg/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___sg"
  }, {
    path: "/sg/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___sg"
  }, {
    path: "/sg/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___sg"
  }, {
    path: "/sg/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___sg"
  }, {
    path: "/sg/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___sg"
  }, {
    path: "/sg/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___sg"
  }, {
    path: "/sg/Boutiques",
    component: _34cef09d,
    name: "Boutiques___sg"
  }, {
    path: "/sg/Cart",
    component: _544757fc,
    name: "Cart___sg"
  }, {
    path: "/sg/Category",
    component: _0b2841e0,
    name: "Category___sg"
  }, {
    path: "/sg/checkout",
    component: _48ef6e28,
    name: "checkout___sg",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___sg"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___sg"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___sg"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___sg"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___sg"
    }]
  }, {
    path: "/sg/Checkout",
    component: _48ef6e28,
    name: "Checkout___sg",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___sg"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___sg"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___sg"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___sg"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___sg"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___sg"
    }]
  }, {
    path: "/sg/ContactUs",
    component: _7069626c,
    name: "ContactUs___sg"
  }, {
    path: "/sg/Designers",
    component: _f8dba514,
    name: "Designers___sg"
  }, {
    path: "/sg/Editorial",
    component: _107880d5,
    name: "Editorial___sg"
  }, {
    path: "/sg/Home",
    component: _8f04537e,
    name: "Home___sg"
  }, {
    path: "/sg/MyAccount",
    component: _10189cef,
    name: "MyAccount___sg",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___sg"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___sg"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___sg"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___sg"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___sg"
    }]
  }, {
    path: "/sg/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___sg"
  }, {
    path: "/sg/Newsletter",
    component: _a85becca,
    name: "Newsletter___sg"
  }, {
    path: "/sg/Page",
    component: _08b2ebf1,
    name: "Page___sg"
  }, {
    path: "/sg/Product",
    component: _4bba9d7d,
    name: "Product___sg"
  }, {
    path: "/sg/Projects",
    component: _2acc7f5c,
    name: "Projects___sg"
  }, {
    path: "/sg/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___sg"
  }, {
    path: "/sg/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___sg"
  }, {
    path: "/sg/Route",
    component: _34cba952,
    name: "Route___sg"
  }, {
    path: "/sg/search",
    component: _6f8b500a,
    name: "search___sg"
  }, {
    path: "/sg/Search",
    component: _6f8b500a,
    name: "Search___sg"
  }, {
    path: "/sg/sitemap",
    component: _16372a43,
    name: "sitemap___sg"
  }, {
    path: "/sg/Sitemap",
    component: _16372a43,
    name: "Sitemap___sg"
  }, {
    path: "/sg/wishlist",
    component: _3fbd01b2,
    name: "wishlist___sg"
  }, {
    path: "/sg/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___sg"
  }, {
    path: "/th/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___th"
  }, {
    path: "/th/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___th"
  }, {
    path: "/th/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___th"
  }, {
    path: "/th/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___th"
  }, {
    path: "/th/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___th"
  }, {
    path: "/th/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___th"
  }, {
    path: "/th/Boutiques",
    component: _34cef09d,
    name: "Boutiques___th"
  }, {
    path: "/th/Cart",
    component: _544757fc,
    name: "Cart___th"
  }, {
    path: "/th/Category",
    component: _0b2841e0,
    name: "Category___th"
  }, {
    path: "/th/checkout",
    component: _48ef6e28,
    name: "checkout___th",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___th"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___th"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___th"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___th"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___th"
    }]
  }, {
    path: "/th/Checkout",
    component: _48ef6e28,
    name: "Checkout___th",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___th"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___th"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___th"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___th"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___th"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___th"
    }]
  }, {
    path: "/th/ContactUs",
    component: _7069626c,
    name: "ContactUs___th"
  }, {
    path: "/th/Designers",
    component: _f8dba514,
    name: "Designers___th"
  }, {
    path: "/th/Editorial",
    component: _107880d5,
    name: "Editorial___th"
  }, {
    path: "/th/Home",
    component: _8f04537e,
    name: "Home___th"
  }, {
    path: "/th/MyAccount",
    component: _10189cef,
    name: "MyAccount___th",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___th"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___th"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___th"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___th"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___th"
    }]
  }, {
    path: "/th/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___th"
  }, {
    path: "/th/Newsletter",
    component: _a85becca,
    name: "Newsletter___th"
  }, {
    path: "/th/Page",
    component: _08b2ebf1,
    name: "Page___th"
  }, {
    path: "/th/Product",
    component: _4bba9d7d,
    name: "Product___th"
  }, {
    path: "/th/Projects",
    component: _2acc7f5c,
    name: "Projects___th"
  }, {
    path: "/th/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___th"
  }, {
    path: "/th/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___th"
  }, {
    path: "/th/Route",
    component: _34cba952,
    name: "Route___th"
  }, {
    path: "/th/search",
    component: _6f8b500a,
    name: "search___th"
  }, {
    path: "/th/Search",
    component: _6f8b500a,
    name: "Search___th"
  }, {
    path: "/th/sitemap",
    component: _16372a43,
    name: "sitemap___th"
  }, {
    path: "/th/Sitemap",
    component: _16372a43,
    name: "Sitemap___th"
  }, {
    path: "/th/wishlist",
    component: _3fbd01b2,
    name: "wishlist___th"
  }, {
    path: "/th/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___th"
  }, {
    path: "/uae/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___uae"
  }, {
    path: "/uae/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___uae"
  }, {
    path: "/uae/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___uae"
  }, {
    path: "/uae/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___uae"
  }, {
    path: "/uae/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___uae"
  }, {
    path: "/uae/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___uae"
  }, {
    path: "/uae/Boutiques",
    component: _34cef09d,
    name: "Boutiques___uae"
  }, {
    path: "/uae/Cart",
    component: _544757fc,
    name: "Cart___uae"
  }, {
    path: "/uae/Category",
    component: _0b2841e0,
    name: "Category___uae"
  }, {
    path: "/uae/checkout",
    component: _48ef6e28,
    name: "checkout___uae",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___uae"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___uae"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___uae"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___uae"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___uae"
    }]
  }, {
    path: "/uae/Checkout",
    component: _48ef6e28,
    name: "Checkout___uae",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___uae"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___uae"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___uae"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___uae"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___uae"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___uae"
    }]
  }, {
    path: "/uae/ContactUs",
    component: _7069626c,
    name: "ContactUs___uae"
  }, {
    path: "/uae/Designers",
    component: _f8dba514,
    name: "Designers___uae"
  }, {
    path: "/uae/Editorial",
    component: _107880d5,
    name: "Editorial___uae"
  }, {
    path: "/uae/Home",
    component: _8f04537e,
    name: "Home___uae"
  }, {
    path: "/uae/MyAccount",
    component: _10189cef,
    name: "MyAccount___uae",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___uae"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___uae"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___uae"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___uae"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___uae"
    }]
  }, {
    path: "/uae/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___uae"
  }, {
    path: "/uae/Newsletter",
    component: _a85becca,
    name: "Newsletter___uae"
  }, {
    path: "/uae/Page",
    component: _08b2ebf1,
    name: "Page___uae"
  }, {
    path: "/uae/Product",
    component: _4bba9d7d,
    name: "Product___uae"
  }, {
    path: "/uae/Projects",
    component: _2acc7f5c,
    name: "Projects___uae"
  }, {
    path: "/uae/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___uae"
  }, {
    path: "/uae/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___uae"
  }, {
    path: "/uae/Route",
    component: _34cba952,
    name: "Route___uae"
  }, {
    path: "/uae/search",
    component: _6f8b500a,
    name: "search___uae"
  }, {
    path: "/uae/Search",
    component: _6f8b500a,
    name: "Search___uae"
  }, {
    path: "/uae/sitemap",
    component: _16372a43,
    name: "sitemap___uae"
  }, {
    path: "/uae/Sitemap",
    component: _16372a43,
    name: "Sitemap___uae"
  }, {
    path: "/uae/wishlist",
    component: _3fbd01b2,
    name: "wishlist___uae"
  }, {
    path: "/uae/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___uae"
  }, {
    path: "/uk/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___uk"
  }, {
    path: "/uk/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___uk"
  }, {
    path: "/uk/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___uk"
  }, {
    path: "/uk/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___uk"
  }, {
    path: "/uk/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___uk"
  }, {
    path: "/uk/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___uk"
  }, {
    path: "/uk/Boutiques",
    component: _34cef09d,
    name: "Boutiques___uk"
  }, {
    path: "/uk/Cart",
    component: _544757fc,
    name: "Cart___uk"
  }, {
    path: "/uk/Category",
    component: _0b2841e0,
    name: "Category___uk"
  }, {
    path: "/uk/checkout",
    component: _48ef6e28,
    name: "checkout___uk",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___uk"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___uk"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___uk"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___uk"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___uk"
    }]
  }, {
    path: "/uk/Checkout",
    component: _48ef6e28,
    name: "Checkout___uk",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___uk"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___uk"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___uk"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___uk"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___uk"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___uk"
    }]
  }, {
    path: "/uk/ContactUs",
    component: _7069626c,
    name: "ContactUs___uk"
  }, {
    path: "/uk/Designers",
    component: _f8dba514,
    name: "Designers___uk"
  }, {
    path: "/uk/Editorial",
    component: _107880d5,
    name: "Editorial___uk"
  }, {
    path: "/uk/Home",
    component: _8f04537e,
    name: "Home___uk"
  }, {
    path: "/uk/MyAccount",
    component: _10189cef,
    name: "MyAccount___uk",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___uk"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___uk"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___uk"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___uk"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___uk"
    }]
  }, {
    path: "/uk/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___uk"
  }, {
    path: "/uk/Newsletter",
    component: _a85becca,
    name: "Newsletter___uk"
  }, {
    path: "/uk/Page",
    component: _08b2ebf1,
    name: "Page___uk"
  }, {
    path: "/uk/Product",
    component: _4bba9d7d,
    name: "Product___uk"
  }, {
    path: "/uk/Projects",
    component: _2acc7f5c,
    name: "Projects___uk"
  }, {
    path: "/uk/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___uk"
  }, {
    path: "/uk/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___uk"
  }, {
    path: "/uk/Route",
    component: _34cba952,
    name: "Route___uk"
  }, {
    path: "/uk/search",
    component: _6f8b500a,
    name: "search___uk"
  }, {
    path: "/uk/Search",
    component: _6f8b500a,
    name: "Search___uk"
  }, {
    path: "/uk/sitemap",
    component: _16372a43,
    name: "sitemap___uk"
  }, {
    path: "/uk/Sitemap",
    component: _16372a43,
    name: "Sitemap___uk"
  }, {
    path: "/uk/wishlist",
    component: _3fbd01b2,
    name: "wishlist___uk"
  }, {
    path: "/uk/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___uk"
  }, {
    path: "/us/all-designers-men",
    component: _5b4c2c9d,
    name: "all-designers-men___us"
  }, {
    path: "/us/all-designers-women",
    component: _8a61a9f6,
    name: "all-designers-women___us"
  }, {
    path: "/us/AllDesignersMen",
    component: _5b4c2c9d,
    name: "AllDesignersMen___us"
  }, {
    path: "/us/AllDesignersWomen",
    component: _8a61a9f6,
    name: "AllDesignersWomen___us"
  }, {
    path: "/us/archivesalebergamo",
    component: _2c00cb04,
    meta: {"hidePrefooterForDesktop":true},
    name: "archivesalebergamo___us"
  }, {
    path: "/us/ArchiveSaleBergamo",
    component: _2c00cb04,
    name: "ArchiveSaleBergamo___us"
  }, {
    path: "/us/Boutiques",
    component: _34cef09d,
    name: "Boutiques___us"
  }, {
    path: "/us/Cart",
    component: _544757fc,
    name: "Cart___us"
  }, {
    path: "/us/Category",
    component: _0b2841e0,
    name: "Category___us"
  }, {
    path: "/us/checkout",
    component: _48ef6e28,
    name: "checkout___us",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___us"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___us"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___us"
    }, {
      path: "thank-you",
      component: _42a0bda8,
      name: "thank-you___us"
    }, {
      path: "user-account",
      component: _7855b4db,
      name: "user-account___us"
    }]
  }, {
    path: "/us/Checkout",
    component: _48ef6e28,
    name: "Checkout___us",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___us"
    }, {
      path: "ExternalCheckoutThankYou",
      component: _97e42006,
      name: "Checkout-ExternalCheckoutThankYou___us"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___us"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___us"
    }, {
      path: "ThankYou",
      component: _42a0bda8,
      name: "Checkout-ThankYou___us"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___us"
    }]
  }, {
    path: "/us/ContactUs",
    component: _7069626c,
    name: "ContactUs___us"
  }, {
    path: "/us/Designers",
    component: _f8dba514,
    name: "Designers___us"
  }, {
    path: "/us/Editorial",
    component: _107880d5,
    name: "Editorial___us"
  }, {
    path: "/us/Home",
    component: _8f04537e,
    name: "Home___us"
  }, {
    path: "/us/MyAccount",
    component: _10189cef,
    name: "MyAccount___us",
    children: [{
      path: "AddressesDetails",
      component: _6f2f1c30,
      name: "MyAccount-AddressesDetails___us"
    }, {
      path: "MyProfile",
      component: _a43397c6,
      name: "MyAccount-MyProfile___us"
    }, {
      path: "NewsletterProfiling",
      component: _071fdb2d,
      name: "MyAccount-NewsletterProfiling___us"
    }, {
      path: "OrderHistory",
      component: _42139b54,
      name: "MyAccount-OrderHistory___us"
    }, {
      path: "Overview",
      component: _016e88e9,
      name: "MyAccount-Overview___us"
    }]
  }, {
    path: "/us/newsletter",
    component: _a85becca,
    meta: {"hidePrefooterForDesktop":true,"hidePrefooterForMobile":true},
    name: "newsletter___us"
  }, {
    path: "/us/Newsletter",
    component: _a85becca,
    name: "Newsletter___us"
  }, {
    path: "/us/Page",
    component: _08b2ebf1,
    name: "Page___us"
  }, {
    path: "/us/Product",
    component: _4bba9d7d,
    name: "Product___us"
  }, {
    path: "/us/Projects",
    component: _2acc7f5c,
    name: "Projects___us"
  }, {
    path: "/us/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___us"
  }, {
    path: "/us/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___us"
  }, {
    path: "/us/Route",
    component: _34cba952,
    name: "Route___us"
  }, {
    path: "/us/search",
    component: _6f8b500a,
    name: "search___us"
  }, {
    path: "/us/Search",
    component: _6f8b500a,
    name: "Search___us"
  }, {
    path: "/us/sitemap",
    component: _16372a43,
    name: "sitemap___us"
  }, {
    path: "/us/Sitemap",
    component: _16372a43,
    name: "Sitemap___us"
  }, {
    path: "/us/wishlist",
    component: _3fbd01b2,
    name: "wishlist___us"
  }, {
    path: "/us/Wishlist",
    component: _3fbd01b2,
    name: "Wishlist___us"
  }, {
    path: "/au/c/:categoryId",
    component: _0b2841e0,
    name: "category___au"
  }, {
    path: "/au/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___au"
  }, {
    path: "/au/p/:productId",
    component: _4bba9d7d,
    name: "product___au"
  }, {
    path: "/bh/c/:categoryId",
    component: _0b2841e0,
    name: "category___bh"
  }, {
    path: "/bh/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___bh"
  }, {
    path: "/bh/p/:productId",
    component: _4bba9d7d,
    name: "product___bh"
  }, {
    path: "/ca/c/:categoryId",
    component: _0b2841e0,
    name: "category___ca"
  }, {
    path: "/ca/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ca"
  }, {
    path: "/ca/p/:productId",
    component: _4bba9d7d,
    name: "product___ca"
  }, {
    path: "/ch/c/:categoryId",
    component: _0b2841e0,
    name: "category___ch"
  }, {
    path: "/ch/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___ch"
  }, {
    path: "/ch/p/:productId",
    component: _4bba9d7d,
    name: "product___ch"
  }, {
    path: "/cn/c/:categoryId",
    component: _0b2841e0,
    name: "category___cn"
  }, {
    path: "/cn/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___cn"
  }, {
    path: "/cn/p/:productId",
    component: _4bba9d7d,
    name: "product___cn"
  }, {
    path: "/en/c/:categoryId",
    component: _0b2841e0,
    name: "category___en"
  }, {
    path: "/en/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___en"
  }, {
    path: "/en/p/:productId",
    component: _4bba9d7d,
    name: "product___en"
  }, {
    path: "/eu/c/:categoryId",
    component: _0b2841e0,
    name: "category___eu"
  }, {
    path: "/eu/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___eu"
  }, {
    path: "/eu/p/:productId",
    component: _4bba9d7d,
    name: "product___eu"
  }, {
    path: "/eue/c/:categoryId",
    component: _0b2841e0,
    name: "category___eue"
  }, {
    path: "/eue/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___eue"
  }, {
    path: "/eue/p/:productId",
    component: _4bba9d7d,
    name: "product___eue"
  }, {
    path: "/exu/c/:categoryId",
    component: _0b2841e0,
    name: "category___exu"
  }, {
    path: "/exu/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___exu"
  }, {
    path: "/exu/p/:productId",
    component: _4bba9d7d,
    name: "product___exu"
  }, {
    path: "/fr/c/:categoryId",
    component: _0b2841e0,
    name: "category___fr"
  }, {
    path: "/fr/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___fr"
  }, {
    path: "/fr/p/:productId",
    component: _4bba9d7d,
    name: "product___fr"
  }, {
    path: "/hk/c/:categoryId",
    component: _0b2841e0,
    name: "category___hk"
  }, {
    path: "/hk/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___hk"
  }, {
    path: "/hk/p/:productId",
    component: _4bba9d7d,
    name: "product___hk"
  }, {
    path: "/it/c/:categoryId",
    component: _0b2841e0,
    name: "category___it"
  }, {
    path: "/it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___it"
  }, {
    path: "/it/p/:productId",
    component: _4bba9d7d,
    name: "product___it"
  }, {
    path: "/jp/c/:categoryId",
    component: _0b2841e0,
    name: "category___jp"
  }, {
    path: "/jp/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___jp"
  }, {
    path: "/jp/p/:productId",
    component: _4bba9d7d,
    name: "product___jp"
  }, {
    path: "/kr/c/:categoryId",
    component: _0b2841e0,
    name: "category___kr"
  }, {
    path: "/kr/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___kr"
  }, {
    path: "/kr/p/:productId",
    component: _4bba9d7d,
    name: "product___kr"
  }, {
    path: "/mo/c/:categoryId",
    component: _0b2841e0,
    name: "category___mo"
  }, {
    path: "/mo/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___mo"
  }, {
    path: "/mo/p/:productId",
    component: _4bba9d7d,
    name: "product___mo"
  }, {
    path: "/qa/c/:categoryId",
    component: _0b2841e0,
    name: "category___qa"
  }, {
    path: "/qa/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___qa"
  }, {
    path: "/qa/p/:productId",
    component: _4bba9d7d,
    name: "product___qa"
  }, {
    path: "/row/c/:categoryId",
    component: _0b2841e0,
    name: "category___row"
  }, {
    path: "/row/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___row"
  }, {
    path: "/row/p/:productId",
    component: _4bba9d7d,
    name: "product___row"
  }, {
    path: "/sg/c/:categoryId",
    component: _0b2841e0,
    name: "category___sg"
  }, {
    path: "/sg/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___sg"
  }, {
    path: "/sg/p/:productId",
    component: _4bba9d7d,
    name: "product___sg"
  }, {
    path: "/th/c/:categoryId",
    component: _0b2841e0,
    name: "category___th"
  }, {
    path: "/th/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___th"
  }, {
    path: "/th/p/:productId",
    component: _4bba9d7d,
    name: "product___th"
  }, {
    path: "/uae/c/:categoryId",
    component: _0b2841e0,
    name: "category___uae"
  }, {
    path: "/uae/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___uae"
  }, {
    path: "/uae/p/:productId",
    component: _4bba9d7d,
    name: "product___uae"
  }, {
    path: "/uk/c/:categoryId",
    component: _0b2841e0,
    name: "category___uk"
  }, {
    path: "/uk/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___uk"
  }, {
    path: "/uk/p/:productId",
    component: _4bba9d7d,
    name: "product___uk"
  }, {
    path: "/us/c/:categoryId",
    component: _0b2841e0,
    name: "category___us"
  }, {
    path: "/us/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___us"
  }, {
    path: "/us/p/:productId",
    component: _4bba9d7d,
    name: "product___us"
  }, {
    path: "/au/:path+",
    component: _34cba952,
    name: "route-resolver___au"
  }, {
    path: "/bh/:path+",
    component: _34cba952,
    name: "route-resolver___bh"
  }, {
    path: "/ca/:path+",
    component: _34cba952,
    name: "route-resolver___ca"
  }, {
    path: "/ch/:path+",
    component: _34cba952,
    name: "route-resolver___ch"
  }, {
    path: "/cn/:path+",
    component: _34cba952,
    name: "route-resolver___cn"
  }, {
    path: "/en/:path+",
    component: _34cba952,
    name: "route-resolver___en"
  }, {
    path: "/eu/:path+",
    component: _34cba952,
    name: "route-resolver___eu"
  }, {
    path: "/eue/:path+",
    component: _34cba952,
    name: "route-resolver___eue"
  }, {
    path: "/exu/:path+",
    component: _34cba952,
    name: "route-resolver___exu"
  }, {
    path: "/fr/:path+",
    component: _34cba952,
    name: "route-resolver___fr"
  }, {
    path: "/hk/:path+",
    component: _34cba952,
    name: "route-resolver___hk"
  }, {
    path: "/it/:path+",
    component: _34cba952,
    name: "route-resolver___it"
  }, {
    path: "/jp/:path+",
    component: _34cba952,
    name: "route-resolver___jp"
  }, {
    path: "/kr/:path+",
    component: _34cba952,
    name: "route-resolver___kr"
  }, {
    path: "/mo/:path+",
    component: _34cba952,
    name: "route-resolver___mo"
  }, {
    path: "/qa/:path+",
    component: _34cba952,
    name: "route-resolver___qa"
  }, {
    path: "/row/:path+",
    component: _34cba952,
    name: "route-resolver___row"
  }, {
    path: "/sg/:path+",
    component: _34cba952,
    name: "route-resolver___sg"
  }, {
    path: "/th/:path+",
    component: _34cba952,
    name: "route-resolver___th"
  }, {
    path: "/uae/:path+",
    component: _34cba952,
    name: "route-resolver___uae"
  }, {
    path: "/uk/:path+",
    component: _34cba952,
    name: "route-resolver___uk"
  }, {
    path: "/us/:path+",
    component: _34cba952,
    name: "route-resolver___us"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
